<template>
  <div id="app">
    <Tabs></Tabs>
    <!-- 内容区 -->
    <div class="content_box">
      <div class="content">
        <!-- 导航 -->
        <div class="nav_box">
          <!-- 一级导航 -->
          <div class="one_box">
            <div>{{ one_name }}</div>
            <div class="choose_btn" @click="dialogVisible = true">
              切换考试
              <img src="../../../assets/img/xuanze.png" alt="" />
            </div>
          </div>
          <!-- 二级导航 -->
          <div class="two_box">
            <div class="two_left">专业科目</div>
            <div class="two_right">
              <div
                class="major_el"
                :class="[choose_two == item.chapter_category_id ? 'major' : '']"
                v-for="item in two_nav"
                :key="item.chapter_category_id"
                @click="cutList(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <!-- 列表 -->
        <Menu></Menu>
        <!-- 空状态 -->
        <div class="empty_box" v-if="show_card == true">
          <img src="../../../assets/img/cuoti_null.png" alt="" />
          <p>暂无试卷～</p>
          <button type="button" @click="goTopic">去做题</button>
        </div>
        <div class="list_box" v-else>
          <div v-show="chapters_list != [] || chapters_list.length > 0">
            <div>
              <p>试卷名称</p>
              <p>做题人数</p>
              <p>审核时间</p>
            </div>
            <div class="list_el" v-for="item in chapters_list" :key="item.id">
              <img class="icon" src="../../../assets/img/shijuan.png" alt="" />
              <div class="title_content">
                <!-- 复克鲁克斯酱豆腐考虑时间紧急的时间风口浪尖上的开发建设的是的开发的飞机离开了辅导功课伦敦上空飞过开裆裤 -->
                {{ item.papers_title }}
              </div>
              <div class="number">
                <span>{{ item.people_nums }}</span>
                <span>{{ item.updatetime | formatDate }}</span>
              </div>
              <!-- is_finish 1交卷 0 保存进度 -->
              <button
                class="start_btn"
                type="button"
                @click="goOption(item)"
                v-if="item.is_finish == 1"
              >
                开始做题
              </button>
              <button
                class="start_btn"
                type="button"
                @click="goOption(item)"
                v-else
              >
                继续做题
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- dialog框 -->
    <el-dialog title="请选择专业科目" :visible.sync="dialogVisible" width="33%">
      <!-- <span>这是一段信息</span> -->
      <div class="chapter_category_box">
        <div
          class="chapter_category_el"
          v-for="item in one_nav"
          :key="item.id"
          @click="choose_major(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <Foot></Foot>
  </div>
</template>

<script>
import api from "@/api/api";
export default {
  data() {
    return {
      banner: "/assets/img/infomation.jpg", //banner
      dialogVisible: false, //弹窗
      one_nav: [], //一级导航
      one_id: "", // 一级导航id
      one_name: "", //一级导航名字
      two_nav: [], //二级导航
      two_name: "", //二级导航名字
      choose_two: "", // 二级导航默认选中
      chapters_list: [], //章节列表
      show_card: false, // 是否展示空状态
      menu: [
        {
          id: 1,
          icon: require("../../../assets/img/lianxi.png"),
        },
        {
          id: 2,
          icon: require("../../../assets/img/yilian_normal.png"),
        },
        {
          id: 3,
          icon: require("../../../assets/img/zhenti_normal.png"),
        },
        {
          id: 4,
          icon: require("../../../assets/img/moni_normal.png"),
        },
        {
          id: 5,
          icon: require("../../../assets/img/jilu_normal.png"),
        },
        {
          id: 6,
          icon: require("../../../assets/img/cuoti_normal.png"),
        },
        {
          id: 7,
          icon: require("../../../assets/img/shoucang_normal.png"),
        },
      ], // 菜单
      value: [], //切换考试的专业
    };
  },
  filters: {
    // 时间转换
    formatDate: function (value) {
      let date = new Date(value * 1000);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      // let h = date.getHours();
      // h = h < 10 ? ('0' + h) : h;
      // let m = date.getMinutes();
      // m = m < 10 ? ('0' + m) : m;
      // let s = date.getSeconds();
      // s = s < 10 ? ('0' + s) : s;
      return y + "-" + MM + "-" + d;
    },
  },
  created() {
    this.one_id = sessionStorage.getItem('chapterId')
    this.getOneChapter();
    let sto = this.$store.state.DbSource;
    // console.log("store", sto);
    // // console.log("store", this.$store.state.currDbSource);
  },
  methods: {
    // 去做题
    goTopic() {
      this.$router.push({
        name: "Chapter",
        params: { is_pageid: 9, select_id: 1 },
      });
    },
    name() {
      this.one_name = this.value.name;
      // console.log("下拉", this.value);
      this.getTwoChapter(this.value.id);
    },
    //   获取一级章节分类
    getOneChapter() {
      api.chapter_category().then((res) => {
        // console.log("一级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.one_nav = res.data.data.list;
          // this.one_name = res.data.data.list[0].name;
          if (sessionStorage.getItem("chapterName")) {
            this.one_name = sessionStorage.getItem("chapterName");
          } else {
            this.one_name = res.data.data.list[0].name;
          }
          if (sessionStorage.getItem("chapterId")) {
            this.getTwoChapter(sessionStorage.getItem("chapterId"));
            this.one_id = sessionStorage.getItem("chapterId");
          } else {
            this.getTwoChapter(res.data.data.list[0].id);
            this.one_id = res.data.data.list[0].id;
          }
        }
      });
    },
    // 点击一级导航切换章节
    choose_major(el) {
      // // console.log("章节", el);
      this.getTwoChapter(el.id);
      sessionStorage.setItem("chapterId", el.id);
      sessionStorage.setItem("chapterName", el.name);
      this.one_id = el.id;
      this.one_name = el.name;
      this.dialogVisible = false;
    },
    //   获取二级章节分类
    getTwoChapter(id) {
      var data = {
        chapter_category: id,
        chapter_category_id: id,
      };
      api.chapter_list(data).then((res) => {
        // console.log("二级分类", res);
        if (parseInt(res.data.code) == 1) {
          this.two_nav = res.data.data.list;
          // // console.log(this.two_nav);
          //   this.chapters_list = res.data.data.list[0].chapters;
          this.choose_two = this.two_nav[0].chapter_category_id;
          this.getTrueList(this.two_nav[0].chapter_category_id);
          // // console.log(this.two_nav[0].chapter_category_id);
        }
      });
    },
    //   获取真题试卷列表
    getTrueList(id) {
      var data = {
        chapter_category: this.one_id,
        // chapter_category_id: id,
        paper_type: 2, // 试卷类型 1模拟 2真题
        three_chapcate_id: this.choose_two,
      };
      api.ex_papers(data).then((res) => {
        // console.log("试卷", res);
        if (parseInt(res.data.code) == 1) {
          this.chapters_list = res.data.data.list;
          let nullArr = res.data.data.list;
          // 判断返回列表数组是否为空,空的时候展示空状态
          if (nullArr == [] || nullArr == null || nullArr.length <= 0) {
            this.show_card = true;
          } else {
            this.show_card = false;
          }
          //   this.two_nav = res.data.data.list;
          //   // // console.log(this.two_nav);
          //   this.choose_two = this.two_nav[0].chapter_category_id;
          // // console.log(this.two_nav[0].chapter_category_id);
        }
      });
    },
    // 点击二级导航切换列表
    cutList(el) {
      // // console.log("二级导航点击", el);
      //   this.chapters_list = el.chapters;
      this.choose_two = el.chapter_category_id;
      this.getTrueList(el.chapter_category_id);
    },
    // 点击开始做题去答题页
    goOption(el) {
      // console.log(el);
      // 开始做题之前先清除本地做题数据
      sessionStorage.removeItem("user");
      this.$router.push({
        name: "Introduce",
        params: {
          nav_id: 9,
          chapter_id: this.one_id,
          chapter_name: this.one_name,
          paper_id: el.id,
          paper_name: el.papers_title,
          router_name: "历年真题",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-scrollbar__wrap {
  width: 320px;
}
// banner
.banner {
  width: 100%;
  height: 400px;
  object-fit: cover;
}

// 通知
.notice_box {
  width: 100%;
  height: 50px;
  background: #e9f2ff;
  margin-top: -3px;
  .notice_content {
    width: 1200px;
    height: 50px;
    margin: auto;
    display: flex;
    align-items: center;
    img {
      width: 18px;
      height: 16px;
      margin: 0 10px 0 20px;
    }
    .scroll {
      font-size: 16px;
      font-family: PingFang SC;
      font-weight: 500;
      color: #3e7eff;
    }
  }
}
// 内容区
.content_box {
  width: 100%;
  //   height: 1500px;
  background: #f5f5f5;
  overflow: hidden;
  .content {
    width: 1200px;
    margin: auto;
    //   导航
   .nav_box {
      width: 1160px;
      // height: 170px;
      padding:0 20px;
      background: #fff;
      border-radius: 6px;
      margin: 30px 0;
      overflow: hidden;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      //   一级导航
      .one_box {
        width: 100%;
        display: flex;
        position: relative;
        align-items: center;
        margin: 24px 0 26px;
        div {
          font-size: 20px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #222222;
        }
        .select_option {
          position: relative;
          margin-left: 12px;
          /deep/.el-select {
            width: 96px;
            height: 26px;
            background: #3e7eff;
            border-radius: 13px;
            .el-input__inner {
              width: 96px;
              height: 26px;
              // color: red;
              color: rgba(0, 0, 0, 0);
              background: #3e7eff;
              border-radius: 13px;
              padding: 0;
              display: flex;
              justify-content: space-between;
              border: none;
              background-image: url("../../../assets/img/qiehuan.png");
              background-repeat: no-repeat;
              background-size: 13px 12px;
              background-position-x: 72px;
              background-position-y: 7px;
              // display: none;
            }
            .el-input__inner::placeholder {
              color: rgba(0, 0, 0, 0);
              padding-left: 11px;
            }
            .el-input__suffix-inner {
              display: none;
            }
          }

          p {
            position: absolute;
            top: 6px;
            left: 11px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #fff;
            // z-index: -1;
            // z-index: 999;
          }
        }
        .choose_btn {
          width: 81px;
          height: 23px;
          border: 1px solid #3e7eff;
          border-radius: 11px;
          font-size: 13px;
          font-family: PingFang SC;
          font-weight: 500;
          color: #3e7eff;
          line-height: 23px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-left: 10px;
          cursor: pointer;
          img {
            width: 12px;
            height: 8px;
            margin-left: 3px;
          }
        }
      }
      //   二级导航
      .two_box {
        width: 100%;
        // min-height: 65px;
        display: flex;
        .two_left {
          width: 60px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #484848;
          padding: 7px 0;
          // padding-top: 7px;
        }
        .two_right {
          width: 997px;
          margin-left: 49px;
          display: flex;
          flex-wrap: wrap;
          margin-bottom: 18px;
          .major_el {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #202b3a;
            text-align: center;
            padding: 5px 19px;
            cursor: pointer;
          }
          .major {
            color: #3e7eff;
            // background: #3e7eff;
            border-radius: 18px;
            // max-height: 30px;
            // padding-bottom: 7px;
          }
        }
      }
    }
    //   菜单栏
    .menu_box {
      width: 100%;
      height: 130px;
      border-radius: 6px 6px 0 0;
      background: #f9fcff;
      display: flex;
      img {
        width: 170px;
        height: 130px;
      }
    }
    // 空状态
    .empty_box {
      width: 1200px;
      height: 610px;
      background: #fff;
      // margin-top: 131px;
      display: flex;
      flex-flow: column;
      align-items: center;
      margin-bottom: 225px;
      img {
        width: 286px;
        height: 224px;
        margin-top: 131px;
      }
      p {
        font-size: 14px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #999999;
        margin: 29px 0 15px;
      }
      button {
        width: 200px;
        height: 46px;
        background: #3e7eff;
        border-radius: 6px;
        font-size: 16px;
        font-family: PingFang SC;
        font-weight: 500;
        color: #ffffff;
      }
    }
    // 列表
    .list_box {
      width: 1160px;
      min-height: 610px;
      background: #fff;
      padding: 0 20px;
      margin-bottom: 125px;
      box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.05);
      overflow: hidden;

      div {
        // background: pink;
        overflow: hidden;
        div {
          height: 50px;
          font-size: 14px;
          font-family: PingFang SC;
          font-weight: 400;
          color: #666666;
          display: block;
          line-height: 50px;
          display: flex;
          p:nth-child(2) {
            margin: 0 65px 0 758px;
          }
        }
        .list_el {
          display: flex;
          align-items: center;
          border-top: 1px solid #f5f5f5;
          padding: 14px 0;
          .icon {
            width: 19px;
            height: 22px;
          }
          .title_content {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            width: 610px;
            line-height: 20px;
            word-wrap: wrap;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;
            color: #202b3a;
            margin: 0 182px 0 10px;
          }
          .number {
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #666666;
            span:first-child {
              min-width: 41px;
              text-align: center;
            }
            span:last-child {
              display: inline-block;
              min-width: 78px;
              margin-left: 60px;
              margin-right: 52px;
              text-align: center;
            }
          }
          .start_btn {
            width: 100px;
            cursor: pointer;
            height: 34px;
            background: #3e7eff;
            border-radius: 17px;
            // margin: 23px 0;
            font-size: 15px;
            font-family: PingFang SC;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }
}
.one_box::after{
  content: '';
  display: block;
  height: 1px;
  width: 100%;
  background: #f5f5f5;
  position: absolute;
  bottom: -14px;
  left: 0;
}
.chapter_category_box {
  width: 100%;
  display: flex;
  .chapter_category_el {
    cursor: pointer;
    margin-right: 30px;
  }
  .chapter_category_el:hover {
    color: #3e7eff;
  }
}
</style>
